import '../CSS/Faqs.css';
import Faq from './Faq.jsx';
import faq from '../faq.js';

export default function Faqs() {
  const faqEl = faq.map((faq) => {
    return <Faq key={faq.id} {...faq} />;
  });
  return (
    <section
      className="faqs division small-division"
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-once="true"
    >
      <h1 className="sectionHeader">FAQS</h1>
      {faqEl}
    </section>
  );
}

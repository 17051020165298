// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getStorage} from 'firebase/storage'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD4LGOxd7sxVOFDX3e_aQtdpz5FDpFdLbE",
  authDomain: "ubepsaweb-9f970.firebaseapp.com",
  projectId: "ubepsaweb-9f970",
  storageBucket: "ubepsaweb-9f970.appspot.com",
  messagingSenderId: "935111367210",
  appId: "1:935111367210:web:67dda1660f081ed8c88153"
};

// Initialize Firebase
 export const app = initializeApp(firebaseConfig);
 export const storage = getStorage(app)
import { useContext, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Link } from 'react-router-dom';

import UserContext from '../contexts/userContext';

//IMPORTING NECESSARY COMPONENTS
import AnthemPage from '../routes/AnthemPage';

//IMPORTING REQUIRED ASSETS
import '../CSS/Hero.css';
import close from '../images/reject.png';

export default function Hero() {
  const [openPortal, setOpenPortal] = useState(false);

  const { user } = useContext(UserContext);
  return (
    <section
      className="heroSection"
      id="top"
      data-aos="zoom-in"
      data-aos-duration="1000"
    >
      <div
        className="heroSectionText"
        data-aos="zoom-in"
        data-aos-duration="1000"
        data-aos-delay="500"
      >
        <h1 className="heroTitle">
          WELCOME TO UNIVERSITY OF BENIN , PHYSIOTHERAPY STUDENT'S ASSOCIATION
        </h1>
        <p>Joining hands for excellent service</p>

        {/* we will eventulally add th ability for lal students to create accounts but for now, this has to go 😂 */}
        <Link to={'/ubepsa-anthem'} className="getStarted">
          UBEPSA has an anthem!
        </Link>
      </div>
    </section>
  );
}

//IMPORTING REACT DEPENDENCIES
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

//IMPORTING MAIN APP COMPONENT
import App from './App.js';
//IMPORTING STYLING
import './CSS/index.css';

//INITALISING STATE FOR SIGN IN

const root = ReactDOM.createRoot(document.querySelector('#root'));
root.render(
  <>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </>
);

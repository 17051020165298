import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../CSS/AboutUs.css';

const About = () => {
  return (
    <>
      <Header />
      <div className="history-container mb-20">
        <h1 className="sectionHeader"> HISTORY OF UBEPSA </h1>
        <p>
          Like every other associations, the University of Benin Physiotherapy
          Association aka UBEPSA, has had its ups, downs share of glory and not
          so glory, but a major landmark in its history is its Origin. The
          Association stands to build a community of Physiotherapy students in
          Uniben, fostering internal relationships, structure and order amongst
          the students. It's Idea of an association is a "Big Family" composed
          of an Administrative body and its members. This was the foundational
          concept behind the birth of the Association, and although it has
          managed to successfully run with the vision along the course of the
          years and administrations, it has also had its challenges and
          spirit-breaking setbacks that have shaken the Association and its
          drive in achieving its goals.
        </p>
        <br />
        <p>
          The Association started off in 2016 after the first set of
          matriculated students; Los Pioneros into a then infant Physiotherapy
          department, petitioned for the permit to create the body in 2015.
          Which was later granted in their 2nd year, due to some unsaid
          political complexities. They clearly had a concise vision of the
          construct of the Association in mind as they wasted no time in
          conducting the necessary elections for the offices of the executives
          of the association. This Elections saw the birth of the first
          President of the association in the person of Comr. Oghenekaro, the
          first Vice president Comr. Marvelous, the first Editor In chief Comr.
          Honor Oteme, the first Speaker nicknamed Comr. Oreze, as well many
          other pioneering executives who together saw the first running
          administration of the association. One thing the association was
          commonly praised for was its fast growth. While other associations
          kick off with trying to understand the dynamics of the system, UBEPSA
          kicked off with a clear Vision and began implementation and
          mechanization of the necessary plans to accomplish this goal. One of
          which was the famous UBEPSA Health Week. Although these plans could
          not be actualized in the administration of the pioneering executives,
          they ignited the fire and ideas for other successive administrations
          to run with, develop and implement, which has made UBEPSA the
          successful and fast developing association it is Today.
        </p>
        <br />
        <p>
          The Second Administration saw an exciting President and Vice,
          female-female rule. Being the first Female President, Miss Ebubechi
          Anya led a new set of executives into a more fulfilling and productive
          session, which saw the former president now take up the role of the
          Editor in chief, and the amiable speaker retain his position for the
          second time. It was in this administration that the first Physio Vibes
          Magazine was launched serving as a major accomplishment for the
          association. It was also in this administration that the association's
          first ever Symposium was organized. This administration promised
          greatness but was stopped in it's tracks when Comr. Ebubechi willingly
          and for a reason unknown to the public stepped down from her
          administrative office, cutting short her political career. This led to
          a re-election for the office of the president in that same
          dispensation, as the UBEPSA throne could not be left empty. This
          throne was then succeeded by a vision full Edmond Nwana who along with
          his inherited executives organized the first Successful UBEPSA Health
          week comprising of a Symposium, a Dinner and a second launch of the
          Physio Vibes Magazine. It was also in this administration that the
          association hosted its first ever HOD's Cup, through the then Director
          of Sports UBEPSA. Edmond's brother, Edison also rose to become the
          Stakeholders chairman of the association. Also, the Idea of an
          Outreach Committee for the association was birthed by Comrades Charles
          and Osaheni Osadolor, who together began the initiative of MOCCOP:
          Medical Outreach and Community Physiotherapy. In their words "We can't
          just be Physios and no one knows we exist". This initiative was to
          expose Physiotherapy out to the knowledge of the basic individuals of
          the society, and to correct the common stigma of its interpretation as
          "Bone Massagers". This Outreach Committee/Community due to some
          administrative back and forth was dissolved and later rebirthed as
          POC: Physiotherapy Outreach Committee, which has played its role as a
          successful incarnation of its predecessor, fulfilling the original
          goals of the Community, putting Physiotherapy on the Map.
        </p>
        <br />
        <p>
          The third administration saw the then Editor in chief Comr. Honor
          Oteme attain the role of President. The Administration went from
          sharing a secretariat with and in another departmental building to
          building the current UBEPSA secretariat at the Physiotherapy
          departmental floor, bringing about independence and dominance to the
          department. As much as their administration accomplished a great feat
          for the department, it also had its major losses. For one the
          sessional Health week Tradition could not be re-organized, as they
          faced one of the major problems that has greatly affected the progress
          of the association, in its history as well as in the present, which is
          the low financial resource of the association. Which was majorly
          caused by the indifference and nonchalance on the part of the
          association's members in paying the required dues, yet ironically
          expecting the results that would've been funded by their
          contributions. This led to a risen conflict between the Administrative
          and the Members, which went against the entire "Family" idea of the
          Association.
        </p>
        <br />
        <p>
          Furthermore, things were not so smooth from there. The fourth
          administration led by Comr. Babatunde Gabriel Omotosho faced more
          challenges than any other set has. Of course, the path that bears the
          roses, also bears the thorns. Even the not so prominent challenges
          that had garnered over the years seemed to manifest greatly in his
          time. It was the valley time for UBEPSA. No other leader but Comr.
          Babatunde could have managed such an administration, that he was even
          re-elected the following year as the 5th UBEPSA president, becoming
          the first to serve twice in two consecutive regimes. During his
          tenure, the association witnessed reformations such as the induction
          and oath taking ceremony of the pioneer set, the maiden UBEPSA 5-Aside
          football tournament, prosperity games between the members of staff
          UBTH and UBEPSA’s first team, excellent scores during the MRTBN
          accreditation exercise, revamping the MOCCOP to the POC just to
          mention a few.
        </p>
        <br />
        <p>
          Unfortunately factors like the strike action and poor financial state
          of the association hindered carrying into action plans for the annual
          health week and even delayed the execution of some conventional
          programmes to later dates. Comr. Babatunde had a plethora of
          achievements, his greatest feat was in managing and sustaining the
          standards and foundations built by his predecessors amidst the various
          crisis. Unfortunately some members of the association did not see it
          to be so, and thereby called out the administration publicly for their
          perceived ‘ineffectiveness’. This fall out between the government and
          her people drove chaos into the association. Nevertheless in all of
          these, Comr. Babatunde kept sustainability, bankrolled progress in the
          department and created a platform that aided the next administration
          into it's glorious destiny.
        </p>
        <br />
        <p>
          However, like the popular saying goes "The show must go on." 2023 saw
          the inauguration of its 6th administration, led by Comr. Bashir
          Joshua. Although they were not handed much, especially in an era of an
          unstable UBEPSA, these Excos turned the tables, surpassing all
          expectation, re-ignited the fire back into the heart of Ubepsites.
          They came with high momentum and together masoned UBEPSA back into the
          magnificent edifice it is today. One would think they were over hyped,
          but their C.V and works speaks for itself. It was in their regime that
          an ex course rep and speaker of the association by the name Comr. Eze
          Blessed went on ahead to represent the association in the Faculty's
          election winning the title of the BAMSSA President. It was also in
          their time that through the support of the association and
          instrumentally, it's stake holders Chairman, Comr. Ativie Desmond aka
          The Kingmaker, that a member of the association went on to represent
          the department and faculty in the University of Benin SUG elections,
          winning the title of the government's Financial Secretary, which was
          first achieved by the Ex-Ubepsa president Comr. Babatunde. They also
          brought back the Health week Traditions in style, and hosted what
          could be the most successful and exceptional Health Week in the
          association's history, comprising of the Symposium, the dinner and
          award ceremony and an impactful outreach taken to the streets of
          Uselu, which the land would not be forgetting anytime soon. Their
          regime also brought back intellectualism to the association, hosting
          the first ever UBEPSA Essay writing Competition, introduced by the
          then Editor in Chief Comr. Ose. All this was done even amidst the
          still persistent financial deficit crisis. A final Political feat, saw
          the former Director of welfare UBEPSA, Comr. Islamiyat Akanke
          represent the association once again in the BAMSSA elections and
          through the support of the association, secured a win as the current
          Vice-president of the Association. The department also obtained their
          first ever win in the BAMSSA Dean's Cup, a finishing move setting
          UBEPSA strongly on the Map. Most importantly, the regime brought the
          "Family" value back to the association. It was a comforting and
          inspiring finish, setting the pace and a renewed standard for future
          generations to follow
        </p>
        <br />
        <p>
          Currently, UBEPSA is in its 7th dispensation, with an administration
          led by Comr. Edebor Osamudiamen. After a beautiful finish from the
          6th, one would think it would be all roses for the 7th. But this 7th
          administration were greatly tested when they faced the most
          challenging battle yet. The MRTBN and NUC accreditation tests of the
          department, sent panic through the department and the association. As
          the lives and future of the students in the association depended
          heavily on the results of these accreditations. The impact of the
          indifference of payment of dues and the Financial deficit of the
          association had never been felt so heavily, as a lot of resources;
          both financial and otherwise were needed to refurbish and equip the
          department with its various requirements and necessities, to assure a
          safe accreditation test. But the brilliant administration called the
          Novi Fines, for the first time in the history of the association
          strategically found a way to influence the members into paying the
          required dues, even consider the fact that these dues were raised as a
          result of needs to be met for the accreditation test. Perhaps it was
          the resuscitation of the unity in the association by the 6th or some
          inspiring exhortation made by the President, It cannot be ascertained.
          But one thing was known that never has the members of the association
          feedback to the payment of departmental dues been so outstanding. The
          momentum was created and the administration ran with it, putting
          everything in place and in order for a successful accreditation test.
          Which it turned out to be, the association passing the two bodies
          accreditation, securing the future for its present and future members.
          This same administration witnessed two intellectual individuals
          represent the department at the faculty level for the
          Inter-departmental Debate competition, bringing home the gold and
          securing the name of the association on the people's lips as the next
          big thing. The Administration also, breaking protocols honored the
          great past heroes who played a pivotal role in bringing the
          association to where it is today at the induction ceremony of the
          department's third graduating set. The regime isn't yet over, but has
          left us with high expectations. Surely we're yet to see the other
          great surprises they have in store for us, including the highly
          anticipated UBEPSA health week.
        </p>
        <br />
        <p>
          In conclusion, the University of Benin Physiotherapy Association can
          be said to be one highly blessed with the gift of men. Intellectual,
          Innovative, Brilliant, Determined individuals who have brought the
          association the name, glory and fame it has today. Many of which have
          already been mentioned above. Some other distinguished individuals who
          have played a part in bringing honor to the association's name are the
          likes of Comrades Ose and Jerry who represented the Faculty at the
          Uniben inter-faculty Debate Competition, claiming a win and moving on
          to represent the University at a national level Debate. Comr. Nosa an
          excellent sportsman and UBEPSA's No.1 goal keeper aka "Uniben’s first
          choice" who represented the University at the intervarsity NUGA games.
          Comr. Jean Paul aka The Chest, who won so many awards for the
          department and faculty at the University's 5 Aside competition and in
          the field of sports at large, as well as many other gifted individuals
          who have proven the association's name and the potentials that lies
          within. The association may have gone through a lot, from a steady and
          progressive beginning, to a disheartening and close to dead end path
          along the way and finally back to a revived and highly promising
          future. If the association can strengthen its internal relationship in
          Unity and well groom the potentials that resides within, then we would
          be looking at an association who in no time would take the University
          at large by storm, conquering and dominating sectors and territories
          in the Institution. But then, we can only hope and watch to see what
          the future generation does next.
        </p>
      </div>
      <Footer />
    </>
  );
};

export default About;

export default [
  {
    faq: "How can UBEPSA's website enhance academic excellence among students?",
    answer:
      'THE website is designed to support academic excellence by providing a platform for educational resources and learning materials. Students can benefit from an online environment that complements traditional learning, fostering a more engaging and effective educational experience.',
    id: 1,
  },
  {
    faq: "What issues does UBEPSA's website solve for students?",
    answer:
      "UBEPSA's website addresses challenges such as difficulty accessing study materials, lack of collaboration opportunities, and disorganized information. It streamlines academic resources, enhances collaboration, and provides a centralized hub for student success.",
    id: 2,
  },
  {
    faq: "How is UBEPSA's website addressing the need for a user-friendly platform for students?",
    answer:
      " UBEPSA's website is designed with a user-friendly interface, making it easy for students and faculty to navigate. It addresses the need for a platform that is intuitive, accessible, and enhances the overall user experience.",
    id: 3,
  },
];

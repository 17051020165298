import { ClipLoader } from 'react-spinners';
import React from 'react';

function Loader() {
  return (
    <div
      style={{
        height: '100vh',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ClipLoader color="blue" />
    </div>
  );
}

export default Loader;

import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Loader from '../components/ui/Loader';
import Header from '../components/Header';
import '../CSS/Posts.css';
import { FaTrash } from 'react-icons/fa';
import UserContext from '../contexts/userContext';
import { useContext } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { API } from '../api';
import Footer from '../components/Footer';

//POST COMPONENT START
const Post = () => {
  const [post, setPost] = useState(null);
  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  const { slug } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPost = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${API}/post/${slug}`);
        const postData = response.data;
        setPost(postData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching post:', error);
      }
    };

    fetchPost();
  }, [slug]);

  if (loading) {
    return (
      <>
        <Header />
        <Loader />
      </>
    );
  }

  const handleDeletePost = async (id) => {
    try {
      const response = await axios.delete(`${API}/post/${id}`, {
        withCredentials: true,
      });

      toast.success('Post Deleted');
      navigate('/posts');
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Header />

      {/* ADMIN CONTROLS */}
      <div className="post-container">
        {post ? (
          <div>
            <div className="post-info">
              {user?.isAdmin && (
                <button onClick={() => handleDeletePost(post._id)}>
                  <FaTrash />
                </button>
              )}

              {/* BLOG BEGINNING */}
              <h2>{post.title}</h2>

              {post.image && <img src={post.image} alt="" />}

              <h3 className="author"> {post.writer} </h3>

              <div
                dangerouslySetInnerHTML={{ __html: post.content }}
                className="post-content"
              ></div>
            </div>
            <Footer />
          </div>
        ) : (
          <p>Loading post...</p>
        )}
      </div>
    </>
  );
};

export default Post;

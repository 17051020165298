import { useState } from 'react';
import axios from 'axios';
import '../CSS/Sign.css';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import toast from 'react-hot-toast';
import { API } from '../api';
import show from '../images/show.png';
import hide from '../images/hide.png';

export default function SignUp() {
  const [showPassword, setShowPasword] = useState(show);
  const [pwState, setPwState] = useState('password');
  const handlePwState = () => {
    if (pwState === 'password') {
      setPwState('text');
      setShowPasword(hide);
    } else {
      setPwState('password');
      setShowPasword(show);
    }
  };

  const navigate = useNavigate();
  const [form, setForm] = useState({
    name: '',
    email: '',
    password: '',
    username: '',
  });

  const handleChange = (e) => {
    setForm((prevForm) => {
      return {
        ...prevForm,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(`${API}/auth/sign-up`, form, {
        withCredentials: true,
      });
      const data = res.data;
      toast.success('user registered');
      navigate('/profile');
      console.log(data);
    } catch (error) {
      console.log(error);
      toast.error('something went wrong ');
    }
  };

  return (
    <>
      <Header />
      <div className="sign-div">
        <h1 className="title">Create an account with UBEPSA</h1>

        {/* SIGN UP FORM */}
        <form action="#" className="sign-form" onSubmit={handleSubmit}>
          {/* FORM ITEM */}
          <span className="form-item">
            <label htmlFor="name"> name: </label>

            <input
              type="text"
              placeholder="name"
              name="name"
              id="name"
              value={form.name}
              onChange={handleChange}
              autoComplete="true"
            />
          </span>

          {/* FORM ITEM */}
          <span className="form-item">
            <label htmlFor="email"> Your Email: </label>

            <input
              type="email"
              placeholder="Email"
              name="email"
              id="email"
              value={form.email}
              required
              onChange={handleChange}
              autoComplete="true"
            />
          </span>

          {/* FORM ITEM */}
          <span className="form-item">
            <label htmlFor="userName"> Your username: </label>

            <input
              type="text"
              placeholder="User name"
              name="username"
              id="username"
              value={form.username}
              onChange={handleChange}
              autoComplete="true"
            />
          </span>

          {/* FORM ITEM */}
          <span className="form-item password">
            <label htmlFor="password">  password: </label>
            <img src={showPassword} alt="" onClick={handlePwState} />
            <input
              type={pwState}
              name="password"
              id="password"
              value={form.password}
              onChange={handleChange}
            />
          </span>

          {/* FORM ITEM */}
          {/* <span className="form-item password">
            <label htmlFor="confirmPassword"> Confirm password: </label>
            <img src={showPassword} alt="" onClick={handlePwState} />
            <input
              type={pwState}
              name="confirmPassword"
              id="confirmPassword"
              value={form.confirmPassword}
              onChange={handleChange}
            />
          </span> */}

          <input type="submit" value={'Sign up'} id="submit-button" />
          <p>
            Already have an account ? <Link to={'/sign-in'}>Sign in</Link>
          </p>
        </form>
      </div>
    </>
  );
}

import React from 'react';

//IMPORTING REQUIRED COMPONENTS
import Header from '../components/Header';
import Footer from '../components/Footer';

//IMPORTING REQUIRED ASSETS
import anthemAudio from '../audio/anthem.mp3';

const AnthemPage = ({ close, openPortal, setOpenPortal }) => {
  return (
    <>
      <Header />
      <section className="w-full h-full bg-white p-5 mt-12">
        <div className="flex justify-end pt-2 pr-2">
          <img
            src={close}
            alt=""
            className="w-12 cursor-pointer transition duration-300 hover:scale-125"
            onClick={() => {
              setOpenPortal(false);
            }}
          />
        </div>

        <article className="mt-5 p-5">
          <h1 className="font-bold text-center text-2xl">
            The Physiotherapy Department (UNIBEN) Anthem.
          </h1>
          <p className="mt-3">
            The University of Benin Department of Physiotherapy's Anthem,
            composed with contributions from members of staff and students,
            represents the values that define our profession and guide our
            practice.
          </p>
          <p className="mt-3">
            Whenever this harmonic language sits on our lips, pride swelling in
            our hearts and intense passion fueling our souls, these words become
            our greatest strengths. We reaffirm our vows of allegiance to the
            highest standards of practice, education, and research.
          </p>
          <p className="mt-3">
            Physiotherapy: our noble creed, can be sung on the go, at home, in
            transit, played at public gatherings, everywhere and anywhere
            Physiotherapy is familiar or alien.
          </p>
        </article>
        <div className="anthem flex justify-center mt-5">
          <audio controls>
            <source src={anthemAudio} type="audio/mpeg"></source>
          </audio>
        </div>
        <div className="p-5">
          <h1 className="text-center font-bold mt-10 text-2xl"> Lyrics </h1>
          <section className="flex flex-col gap-10 w-72">
            <p>
              (Verse 1) <br /> With skilled hands and compassionate hearts,
              <br /> We guide patients on their journey to restart,
              <br /> From injury to wellness,
              <br /> we pave the way, Empowering lives,
              <br /> every single day.
            </p>
            <p>
              (Chorus) <br /> Physiotherapy, our noble creed,
              <br /> Restoring function, exceeding need,
              <br /> Movement is life, and we hold the key,
              <br /> Unlocking potential, setting patients free.
            </p>
            <p>
              (Verse 2) <br /> Through exercise and care, we nurture and mend,
              <br /> Helping bodies heal, and spirits transcend,
              <br /> From pediatric to geriatric, we serve with grace,
              <br /> Improving quality of life, leaving a lasting trace.
            </p>
            <p>
              (Chorus) <br /> Physiotherapy, our noble creed,
              <br /> Restoring function, exceeding need,
              <br /> Movement is life, and we hold the key,
              <br /> Unlocking potential, setting patients free.
            </p>
            <p>
              (Verse 3) <br /> With evidence-based practice, we lead the way,
              <br />
              Teaming up with others, for a brighter day,
              <br /> Our passion for physiotherapy shines so bright,
              <br />
              Transforming lives, a beacon of hope and light.
            </p>
            <p>
              (Chorus) <br /> Physiotherapy, our noble creed,
              <br /> Restoring function, exceeding need,
              <br /> Movement is life, and we hold the key,
              <br /> Unlocking potential, setting patients free.
            </p>
          </section>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default AnthemPage;

import { useEffect } from 'react';

import AboutUs from '../components/AboutUs';
import Faqs from '../components/Faqs';
import Header from '../components/Header';
import Heads from '../components/Heads';
import Hero from '../components/Hero';
import Location from '../components/Location';
import QuickAccess from '../components/QuickAccess';
import Footer from '../components/Footer';
import Aos from 'aos';
import 'aos/dist/aos.css';

const Home = () => {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <div>
      <Header />
      <main>
        <Hero />
        <QuickAccess />
        <AboutUs />
        <Heads />
        <Faqs />
        <Location />
        <Footer />
      </main>
    </div>
  );
};
export default Home;

import { useState } from 'react';
import '../CSS/Location.css';
import emailjs from '@emailjs/browser';
import { Form } from 'react-router-dom';

export default function Location() {
  const [formDetails, setFormDetails] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });
  const { name, email, subject, message } = formDetails;

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormDetails((prevForm) => {
      return { ...prevForm, [name]: value };
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const templateParams = {
      to_name: 'ubepsaweb',
      from_name: name,
      message: message,
      sender_email: email,
      subject: subject,
    };
    const templateId = 'template_btp6t2i';
    const publicKey = '8x_frKfwqwutbe7To';
    const serviceId = 'service_wtmr22f';

    emailjs
      .send(serviceId, templateId, templateParams, publicKey)
      .then((resp) => {
        console.log('Email sent successfuly', resp);
        setFormDetails({
          name: '',
          email: '',
          message: '',
          subject: '',
        });
        alert('Your feedback has been submitted');
      })
      .catch((error) => {
        console.error('Error sending email', error);
      });
  };

  return (
    <section className="location-section division small-division">
      <h1 className="sectionHeader">OUR LOCATION</h1>

      <div
        className="location-div"
        data-aos="zoom-in"
        data-aos-duration="1500"
        data-aos-once="true"
      >
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1573.5100334517317!2d5.62424905728561!3d6.394405845260381!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x10472d64bccf8b19%3A0x32cc6998d240169f!2sSchool%20of%20Basic%20Medical%20Sciences%2C%20University%20of%20Benin!5e0!3m2!1sen!2sng!4v1707557117304!5m2!1sen!2sng"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>

      <div className="info-div division small-division" id="feedback">
        <div
          className="info-text"
          data-aos="fade-right"
          data-aos-duration="1500"
          data-aos-once="true"
          data-aos-delay="900"
        >
          <span className="location-span">
            <h1 className="info-title">Location:</h1>
            <p className="info">
              9JVF+PM3, Uniben Road, Uselu, Benin City 300103, Edo
            </p>
          </span>

          <span className="location-span">
            <h1 className="info-title">Email:</h1>
            <p className="info">ubepsaweb@gmail.com</p>
          </span>
        </div>

        {/* THE FORM STARTS HERE */}
        <div
          className="info-form"
          data-aos="fade-left"
          data-aos-duration="1000"
          data-aos-once="true"
          data-aos-delay="500"
        >
          <form onSubmit={handleSubmit} className="form-container">
            <span className="name-email">
              <input
                type="text"
                name="name"
                id="name"
                placeholder="Your name"
                autoComplete="true"
                value={name}
                onChange={handleChange}
              />

              <input
                type="email"
                name="email"
                id="email"
                placeholder="Your email"
                required
                autoComplete="true"
                value={email}
                onChange={handleChange}
              />
            </span>

            <input
              type="text"
              name="subject"
              id="subject"
              placeholder="Subject"
              value={subject}
              onChange={handleChange}
            />

            <textarea
              type="text"
              name="message"
              id="message"
              placeholder="Message"
              value={message}
              onChange={handleChange}
            />

            <button className="px-4 py-2 bg-[#1977cc] text-white font-bold">
              {' '}
              Submit{' '}
            </button>
          </form>
        </div>
      </div>
    </section>
  );
}

import React, { useState } from 'react';

/* IMPORTNG NEEDED COMPONENTS */
import Header from '../components/Header';
import axios from 'axios';

import { API } from '../api';

/* IMPORTING FIREBASE DEPENDECIES */
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from '../firebase.config';

/* IMPORTING EXTERNAL REACT DEPENDENCIES */
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

/* IMPORTING IMAGE ASSETS */
import cameraImg from '../images/photo-camera-interface-symbol-for-button.png';

/* IMPORTING CSS FILE */
import '../CSS/CreatePost.css';

const CreatePost = () => {
  const navigate = useNavigate();
  /* STATE FOR THE CREATE POST FORM */
  const [formData, setFormData] = useState({
    title: '',
    writer: '',
    content: '',
  });

  /* STATES FOR THE IMAGE DISPLAY */
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageDisplay, setImageDisplay] = useState(null);
  const [image, setImage] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const imageUrl = URL.createObjectURL(file);
    setImageDisplay(imageUrl);
    setSelectedFile(file);
  };

  const removeImageDisplay = () => {
    setImageDisplay(null);
    setSelectedFile(null);
  };

  const uploadImageToFirebaseStorage = async () => {
    try {
      if (!selectedFile) {
        console.error('No file selected for upload');
        return;
      }

      const storage = getStorage();
      const storageRef = ref(storage, `images/${selectedFile.name}`);
      const snapshot = await uploadBytes(storageRef, selectedFile);
      const downloadURL = await getDownloadURL(snapshot.ref);
      setImage(downloadURL);
      console.log(downloadURL);
    } catch (error) {
      console.error('Error uploading image to Firebase Storage:', error);
    }
  };

  const handleChange = (e) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (image) {
        const newFormData = { ...formData, image };
        const res = await axios.post(`${API}/post/new`, newFormData, {
          withCredentials: true,
        });
        const data = res.data;
        console.log(data);
        toast.success('Post created successfully');
        navigate('/posts');
      } else {
        const res = await axios.post(`${API}/post/new`, formData, {
          withCredentials: true,
        });
        const data = res.data;
        console.log(data);
        toast.success('Post created successfully');
        navigate('/posts');
      }
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong');
    }
  };

  return (
    <>
      <Header />
      <h1 className="post-page-header sectionHeader"> CREATE NEW POST </h1>

      <label htmlFor="image" className="fileLabel">
        {imageDisplay ? (
          <img src={imageDisplay} alt="" className="w-full h-full" />
        ) : (
          <img src={cameraImg} alt="" className="w-[100px]" />
        )}

        <input
          style={{ display: 'none' }}
          type="file"
          name="image"
          id="image"
          onChange={handleFileChange}
          className="img-uploader"
        />
      </label>
      {imageDisplay && (
        <button
          onClick={removeImageDisplay}
          className="w-12 h-12 block rounded-full bg-slate font-bold bg-[lightgray] relative top-[-5em] left-[77%]"
        >
          X
        </button>
      )}

      <button
        onClick={uploadImageToFirebaseStorage}
        className="w-42 block mx-auto p-2 bg-[#1977cc] text-white font-bold"
      >
        upload Image
      </button>
      <form onSubmit={handleSubmit} className="post-form-container">
        <input
          type="text"
          id="title"
          name="title"
          className="text-input"
          placeholder="Post title"
          onChange={handleChange}
          value={formData.title}
        />
        <input
          type="text"
          id="writer"
          name="writer"
          className="text-input"
          placeholder="Post Author"
          onChange={handleChange}
          value={formData.writer}
        />

        <ReactQuill
          theme="snow"
          className="content"
          placeholder="Write something..."
          onChange={(value) => {
            setFormData({ ...formData, content: value });
          }}
        />
        <button type="submit" value={'Post'} id="submit">
          Submit
        </button>
      </form>
    </>
  );
};

export default CreatePost;

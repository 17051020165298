import React from 'react';

/* IMPORTING REQUIRED COMPONENTS */
import Header from '../components/Header';
import Footer from '../components/Footer';

/* IMPORTING REQUIRED ASSETS */
import sisterPic from '../images/sisterPic.jpg';

const Sister = () => {
  return (
    <>
      <Header />
      <section className="mt-7 py-10 px-6">
        <h1 className="font-bold text-[2em] text-center mt-10">
          About Rev.Sr.(Dr.) Henrietta Fawole
        </h1>
        <div className="bannerPic mt-5 rounded-xl overflow-hidden">
          <img src={sisterPic} alt="" className="w-full" />
        </div>

        {/* POST CONTENT */}
        <div>
          <p className="mt-12 mb-5">
            Rev Sister Dr. Henrietta Fawole, a luminary in the field of
            physiotherapy , she has spent a lifetime illuminating the path of
            countless students with a career spanning over two decades, she is
            endowed with high level of wisdom and compassion and as such she is
            an inspiration to many of her students. Dr Fawole's journey is an
            indication of the power of education and passion. As a devoted
            teacher, mentor, and scholar, she has kindled the love of learning
            in the hearts of many, inspiring her students to embrace their
            potentials and shape a brighter future.
          </p>
          <p className="mb-5">
            Rev. Sister Henrietta Fawole has been an integral part of the
            University of Benin's department of Physiotherapy since it's
            inception. She served as the Ag. Head of Department of
            Physiotherapy, School of Basic Medical Sciences, College of Medical
            Sciences, University of Benin, Benin City, Nigeria.
          </p>
          <p className="mb-5">
            Dr. Fawole's's unwavering faith and devotion to her Catholic beliefs
            have been the bedrock of her life's work. As a Reverend Sister, she
            has dedicated herself to serving the Lord and humanity with
            unrelenting passion. Her deep spiritual roots have nourished her
            commitment to education. . Through her prayerful presence, gentle
            guidance, and selfless service, Dr. Fawole has been a beacon of hope
            and a lasting impact on her community and beyond.
          </p>
          <p className="mb-5">
            'Sister' as she is fondly called by her students is considered
            strict by many but in actual sense, she is a disciplinarian and she
            promotes nothing but integrity. As the erudite scholar that she is,
            she organizes lectures and coordinates research dissertations. She
            has also moderated post graduate students’ thesis seminars in the
            Nursing department of the University of Benin, Nigeria.
          </p>
          <p className="mb-5">
            A mid-career researcher and experienced academic in physiotherapy in
            Nigeria, Sr. Henrietta has demonstrated strength in the areas of
            research, teaching, administration, course design and management.
            Sr. Henrietta is a keen educator, collaborator and researcher. She
            primarily publishes research papers in the area of rheumatology,
            physical activity, epidemiology, musculoskeletal conditions and
            physiotherapy. Her journal publications have been cited nearly 289
            times since 2018.
          </p>
          <p className="mb-5">
            Rev. Sister Henrietta Fawole's career started with a First Class
            degree in Health Science awarded by Paisley University in 2007. She
            completed a pre- registration master’s degree in physiotherapy and a
            PhD in physiotherapy at Glasgow Caledonian University in 2011 and
            2021 respectively. She has a HNC in Health Care from Reid Kerr
            College, Paisley (2004).
          </p>
          <p className="mb-5">
            She has managed a variety of positions including as a member of the
            Health Care Professions Council, United Kingdom and Medical
            Rehabilitation Therapists (Registration) Board of Nigeria (2011).
            She was also a member of the Physiotherapy Curriculum development
            committee, University of Benin, Nigeria (2015) and a member of World
            Health Organization Peer Review Group for Sarcopenia (2022). She was
            awarded the Pragmatic Physical Activity Evaluation Scholarship Grant
            (2017), Glasgow Caledonian University – School Health & Life
            Sciences Conference and Training Award (2017), Glasgow Caledonian
            University – School of Health & Life Sciences Conference and
            Training Award (2018), and Glasgow Caledonian University – School of
            Health & Life Sciences Conference and Training Award (2020).
          </p>
          <p className="mb-5">
            Rev. Sister (Dr.) Henrietta Fawole is a specialist Musculoskeletal
            and Orthopedic Physiotherapist, with complementary research in
            cardio-respiratory disorders and rehabilitation, women’s health and
            nutritional disorders, research methodology and biostatistics,
            intensive care physiotherapy and clinical physiotherapy practice.
          </p>
          <p className="mb-5">
            She is a reviewer for several reputable journals such as Journal of
            Physical Activity and Ageing (2017), Pain Medicine (2020), PLOS One
            (2021), Disability, CBR and Inclusive development (2022), Scientific
            Reports (2022). She is experienced in utilizing innovative computer-
            assisted educational resources in learning and teaching. She is
            involved in course team activities and curriculum development for
            students. She has been instrumental to the growth of physiotherapy
            in the University of Benin, being a pioneer member of staff as well
            as a mother figure.
          </p>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Sister;

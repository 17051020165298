import axios from 'axios';
import { createContext, useState, useEffect } from 'react';
import { API } from '../api.js';
import Loader from '../components/ui/Loader.jsx';
const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getProfile = async () => {
      try {
        const response = await axios.get(`${API}/user/profile`, {
          withCredentials: true,
        });
        setUser(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching user profile:', error);
        setLoading(false);
      }
    };
    getProfile();
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {loading ? (
        <div>
          <Loader />
        </div>
      ) : (
        children
      )}
    </UserContext.Provider>
  );
};

export default UserContext;

import '../CSS/AboutUs.css';
import { useEffect, useState } from 'react';

import { bgArray } from '../imgData';
import { Link } from 'react-router-dom';

import aboutBg from '../images/aboutPic.jpg';

export default function AboutUs() {
  return (
    <section
      className="aboutUs division small-division"
      id="about"
      style={{
        backgroundImage: `url(${aboutBg})`,
        backgroundRepeat: 'none',
        backgroundSize: 'cover',
      }}
      data-aos="zoom-in"
      data-aos-duration="1500"
      data-aos-once="true"
    >
      <h1 className="sectionHeader aboutHeader">ABOUT</h1>
      <div
        className="aboutContent"
        data-aos="zoom-in"
        data-aos-once="true"
        data-aos-duration="1000"
        data-aos-delay="500"
      >
        <div className="aboutText">
          <h1>History of UBEPSA.</h1>
          <p>
            Like every other associations, the University of Benin Physiotherapy
            Association aka UBEPSA, has had its ups, downs share of glory and
            not so glory. But a major landmark in its history is its Origin. The
            Association stands to build a community of Physiotherapy students in
            Uniben, fostering internal relationships, structure and order
            amongst the students. It's Idea of an association is a "Big Family"
            composed of an Administrative body and its members.
          </p>
          <Link to={'./about'} className="aboutLink">
            {' '}
            Read more{' '}
          </Link>
        </div>
      </div>
    </section>
  );
}

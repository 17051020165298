import { Route, Routes } from 'react-router-dom';
import Home from './routes/Home.jsx';
import SignIn from './routes/SignIn.jsx';
import SignUp from './routes/SignUp.jsx';
import DashBoard from './routes/DashBoard.jsx';
import Posts from './routes/Posts.jsx';
import { Toaster } from 'react-hot-toast';
import CreatePost from './routes/CreatePost.jsx';
import { UserProvider } from './contexts/userContext.js';
import Post from './routes/Post.jsx';
import PrivateRoute from './components/AdminRoute.jsx';
import RGhome from './routes/RGhome.jsx';
import About from './routes/About.jsx';
import Sister from './routes/Sister.jsx';
import AnthemPage from './routes/AnthemPage.jsx';

export default function App() {
  return (
    <>
      <UserProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/profile" element={<DashBoard />} />
          <Route path="/create-post" element={<CreatePost />} />
          <Route path="/posts" element={<Posts />} />
          <Route path="/post/:slug" element={<Post />} />
          <Route path="/research-gate-home" element={<RGhome />} />
          <Route path="/about" element={<About />} />
          <Route path="/about-sister" element={<Sister />} />
          <Route path="/ubepsa-anthem" element={<AnthemPage />} />
        </Routes>
      </UserProvider>
      <Toaster />
    </>
  );
}

import arrowDown from '../images/arrow-down-sign-to-navigate.png';
import arrowUp from '../images/upload.png';
import { useState } from 'react';

export default function Faq({ faq, answer }) {
  const [faqState, setFaqState] = useState('closed');

  const openFaq = (e) => {
    if (faqState === 'closed') {
      setFaqState('openFaq');
    } else {
      setFaqState('closed');
    }
  };

  return (
    <article className={`faq ${faqState}`} onClick={openFaq}>
      <div className="faq-title">
        <h3 className="faq-header"> {faq} </h3>
        <div className="faq-img">
          <img src={faqState === 'closed' ? arrowDown : arrowUp} alt="" />
        </div>
      </div>
      <p className="faq-content">{answer}</p>
    </article>
  );
}

import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import UserContext from '../contexts/userContext';
import { useContext } from 'react';
import { API } from '../api';

import '../CSS/Posts.css';
import Header from '../components/Header';
import Loader from '../components/ui/Loader';
import Footer from '../components/Footer';

const Posts = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useContext(UserContext);
  useEffect(() => {
    const getPosts = async () => {
      try {
        setLoading(true);
        const res = await axios.get(`${API}/post/all`, {
          withCredentials: true,
        });
        const data = res.data;
        setPosts(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching posts:', error);
      } finally {
        setLoading(false);
      }
    };
    getPosts();
  }, []);

  if (loading) {
    return (
      <>
        <Header />
        <Loader />
        <Footer />
      </>
    );
  }
  return (
    <>
      <Header />
      <h2 className="page-title sectionHeader mt-24">Posts</h2>

      <div className="post-container">
        {/* LATEST POSTS SECTION */}
        <h1 className="text-2xl font-bold ml-5"> Latest posts </h1>
        <div className="xmd:flex xmd:gap-4 px-5">
          {posts.length > 0 ? (
            posts.map((post, i) => {
              if (i < 3) {
                return (
                  <Link
                    to={`/post/${post.slug}`}
                    key={post._id}
                    className="post xmd:w-[30em] xmd:h-[23em]"
                  >
                    <div className="items-center min-[720px]:flex-row  xmd:h-full flex gap-12 my-4 mx-auto flex-col xmd:flex-col xmd:w-full xmd:gap-5">
                      <div className="post-img xmd:h-[12em] xmd:w-[90%] min-[730px]:h-[10em]">
                        {post.image && (
                          <img
                            src={post.image}
                            alt="Post"
                            className="h-full xmd:w-full"
                          />
                        )}
                      </div>

                      {/* POST TITLE AND CONTENT */}
                      <div className="post-text xmd:mt-5 mx-auto">
                        <h2 className="post-title xmd:text-center">
                          {post.title}
                        </h2>
                        {/*            THE PREVIEW PARAGRAPH HAS BEEN REMOVED TMPORARILY
                        BECAUSE OF THE INCONSISTENCIES IT CAUSING IN STYLING, IT
                        WILL BE ADDED BACK EVENTUALLY. */}
                        <p
                          className="post-summary w-full mx-auto xmd:hidden"
                          dangerouslySetInnerHTML={{
                            __html:
                              post.content.split(' ').slice(0, 20).join(' ') +
                              '...',
                          }}
                        />
                      </div>
                    </div>
                  </Link>
                );
              } else {
                return '';
              }
            })
          ) : (
            <p>No posts available </p>
          )}
        </div>
      </div>

      {/* OTHER POSTS */}

      <div className="px-5">
        <h1 className="text-2xl font-bold mt-24"> All posts </h1>
        {posts.length > 0 ? (
          posts.map((post, i) => {
            if (i > 3) {
              return (
                <Link to={`/post/${post.slug}`} key={post._id}>
                  <div className="post items-center">
                    <div className="post-img">
                      {post.image && (
                        <img src={post.image} alt="Post" className="h-[10em]" />
                      )}
                    </div>

                    {/* POST TITLE AND CONTENT */}
                    <div className="post-text">
                      <h2 className="post-title">{post.title}</h2>
                      <p
                        className="post-summary"
                        dangerouslySetInnerHTML={{
                          __html:
                            post.content.split(' ').slice(0, 20).join(' ') +
                            '...',
                        }}
                      />
                    </div>
                  </div>
                </Link>
              );
            } else {
              return '';
            }
          })
        ) : (
          <p>No posts available </p>
        )}
      </div>

      {user?.isAdmin && (
        <Link className="postCreate" to={'/create-post'}>
          Create New Post
        </Link>
      )}
      <Footer />
    </>
  );
};

export default Posts;

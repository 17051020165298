import Header from '../components/Header';
import { useEffect, useState } from 'react';
import '../CSS/DashBoard.css';
import coverPhoto from '../images/cover-photos/firstCover.jpg';
import { Link, useNavigate } from 'react-router-dom';
import { API } from '../api';
import axios from 'axios';
import toast from 'react-hot-toast';

export default function DashBoard() {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const onLogout = async () => {
    const res = await axios.post(
      `${API}/auth/sign-out`,
      {},
      {
        withCredentials: true,
      }
    );
    navigate('/');
    toast.success('successfully logout');
  };

  const getProfile = async () => {
    try {
      const response = await axios.get(`${API}/user/profile`, {
        withCredentials: true,
      });
      setUser(response.data);
    } catch (error) {
      console.error('Error fetching user profile:', error);
    }
  };

  useEffect(() => {
    getProfile();
  }, []);
  return (
    <>
      <Header />
      <div className="container">
        {/* SECTION FOR COVER PHOTO */}
        <section className="cover-photo">
          <img src={coverPhoto} alt="" />
        </section>
        {/* SECTION FOR PROFILE INFORMATION */}
        <section className="profile">
          <div className="profile-pic">
            <img src={user?.profilepic} alt="" />
          </div>
          <div className="profile-info">
            <h2 className="name">{user?.name}</h2>
          </div>
        </section>

        <section className="userFunc">
          {user?.isAdmin && (
            <Link to={'/create-post'} className="adminLink">
              Create Post
            </Link>
          )}
          <Link to={'/posts'} className="adminLink">
            View Posts
          </Link>
        </section>
      </div>
      <div className="detail-container">
        <p>
          <span className="detail">Email:</span> {user?.email}
        </p>
        <p>
          <span className="detail">Phone:</span> 08012312312
        </p>
        <p>
          <span className="detail">Username:</span> {user?.username}
        </p>
        <button
          onClick={onLogout}
          className=" bg-[#1977cc] text-white font-bold p-2 block mx-auto"
        >
          Logout
        </button>
      </div>
    </>
  );
}

import '../CSS/Footer.css';
export default function Footer() {
  return (
    <footer id="footer">
      <div className="footer-container">
        <div className="footer">
          {/* FOOTER HEADING */}
          <div className="footer-heading footer-1 flex flex-col gap-4">
            <h2>UBEPSA</h2>
            <span>
              <b>Address:</b> 9JVF+PM3, Uniben Road,
              <br />
              Uselu, Benin City 300103, Edo
            </span>
            <span>
              <b>Phone:</b> +234 907 990 0629
            </span>
            <span>
              <b>Email:</b> ubepsaeditorial@gmail.com
            </span>
          </div>

          {/* FOOTER HEADING */}
          <div className="footer-heading footer-3">
            <h2>Talk to us</h2>
            <a className="link" href="#feedback">
              Become a UBEPSA developer
            </a>
            <a className="link" href="#feedback">
              Become a writer!
            </a>
            <a className="link" href="#feedback">
              Become a member of the editorial board{' '}
            </a>
            <a className="link" href="#feedback">
              News we missed?
            </a>
            <a className="link" href="#feedback">
              Send us an email.
            </a>
          </div>
        </div>
        {/* NEWSLETTER EMAIL FORM */}
        <div className="footer-email-form">
          <h2>Newsletter</h2>
          <input
            type="email"
            id="footer-email"
            placeholder="ubepsa@gmail.com"
            required
          />
          <input type="submit" id="footer-email-btn" value="sign up" />
        </div>
      </div>
    </footer>
  );
}

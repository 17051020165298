import '../CSS/Heads.css';
import prof from '../images/page-fillers/prof.jpg';
import sister from '../images/page-fillers/hod.jpg';
import drOba from '../images/page-fillers/drOba.jpg';

import { Link } from 'react-router-dom';

export default function Heads() {
  return (
    <section
      className="division deptHeads small-division"
      data-aos="fade-up"
      data-aos-duration="1500"
      data-aos-once="true"
    >
      <h1 className="sectionHeader">Our Esteemed Lecturers</h1>

      <div className="introCards">
        {/* CARD */}
        <div
          className="deptHeadCard"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="1000"
          data-aos-once="true"
        >
          <div className="deptHeadImg">
            <img src={drOba} alt="Hod of the department" />
          </div>
          <h3 className="deptHeadPos">Head of the Department</h3>
          <p className="deptHeadInfo">Dr. Chigozie Obaseki</p>
        </div>

        {/* CARD */}
        <div
          className="deptHeadCard"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="900"
          data-aos-once="true"
        >
          <div className="deptHeadImg">
            <img src={prof} alt="Proffesor of the department" />
          </div>
          <h3 className="deptHeadPos">Professor of the Department</h3>
          <p className="deptHeadInfo">Prof. Kayode Oke</p>
        </div>

        {/* CARD */}
        <div
          className="deptHeadCard"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="900"
          data-aos-once="true"
        >
          <div className="deptHeadImg">
            <img src={sister} alt="Proffesor of the department" />
          </div>
          <h3 className="deptHeadPos">In honour of: </h3>
          <p className="deptHeadInfo">Rev.Sr.(Dr.) Henrietta Fawole</p>
          <Link to={'/about-sister'}> Read more </Link>
        </div>
      </div>
    </section>
  );
}

import React, { useState } from 'react';
import img from '../images/rg-images/image-space-capsule-portrait.jpg';

const Search = () => {
  const [input, setInput] = useState({
    search: '',
  });

  const handleChange = (e) => {
    setInput((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Submitted');
  };

  return (
    <div className="search-container">
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="search"
          id="search"
          placeholder="Search publications"
          value={input.search}
          onChange={handleChange}
        />
        <input type="submit" value="Search" className="button" />
      </form>
      <div className="searchImg">
        <img src={img} alt="" />
      </div>
    </div>
  );
};

export default Search;

import '../CSS/Header.css';
import burger from '../images/menu.png';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import UserContext from '../contexts/userContext';
export default function Header() {
  const { user } = useContext(UserContext);
  const burgerHandler = () => {
    const header = document.querySelector('header');
    if (header.className.includes('default')) {
      header.classList.toggle('open');
    }
  };

  return (
    <header className="default">
      <div className="logoArea">
        <Link className="headerLink" to={'/'}>
          <h1 className="logo">UBEPSA</h1>
        </Link>
        <div id="burgerMenu" onClick={burgerHandler}>
          <img src={burger} alt="" />
        </div>
      </div>

      {/* <!-- --NAVIGATION-- --> */}
      <nav>
        <ul>
          <Link className="headerLink" to={'/'}>
            <li>Home</li>
          </Link>

          {user ? (
            <Link className="headerLink" to={'/profile'}>
              <li>Profile</li>
            </Link>
          ) : (
            ''
          )}

          <li>
            {' '}
            <a className="link" href="#about">
              About{' '}
            </a>
          </li>

          <Link className="headerLink" to={'/posts'}>
            <li>Latest News</li>
          </Link>

          {!user && (
            <Link className="headerLink" to={'/sign-in'}>
              <li>Sign in</li>
            </Link>
          )}

          <Link className="headerLink" to={'/research-gate-home'}>
            {' '}
            <li>UBEPSA Online Library (Coming soon!)</li>{' '}
          </Link>
        </ul>
      </nav>
      {/* <!-- END OF NAVIGATION --> */}
    </header>
  );
}

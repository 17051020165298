import React from 'react';
import '../CSS/rg.css';
import Header from '../components/Header.jsx';
import Search from '../RG components/Search.jsx';
import heroImage from '../images/image1.jpg';
import Footer from '../components/Footer.jsx';

const RGhome = () => {
  return (
    <>
      <Header />
      <div className="rgContainer ">
        <section className="search division small-division" id="search">
          <h2 className="sectionHeader"> GET STARTED </h2>
          <Search />
        </section>
      </div>
      <Footer />
    </>
  );
};

export default RGhome;

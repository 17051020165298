import axios from 'axios';
import '../CSS/Sign.css';
import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import toast from 'react-hot-toast';
import { API } from '../api';
import show from '../images/show.png';
import hide from '../images/hide.png';

export default function SignIn() {
  const [showPassword, setShowPasword] = useState(show);
  const [pwState, setPwState] = useState('password');
  const handlePwState = () => {
    if (pwState === 'password') {
      setPwState('text');
      setShowPasword(hide);
    } else {
      setPwState('password');
      setShowPasword(show);
    }
  };

  const navigate = useNavigate();
  const [form, setForm] = useState({
    email: '',
    password: '',
  });

  const handleChange = (e) => {
    setForm((prevForm) => {
      return {
        ...prevForm,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(`${API}/auth/sign-in`, form, {
        withCredentials: true,
      });
      const data = res.data;
      toast.success('Successfully Logged in');
      navigate('/profile');
    } catch (error) {
      toast.error('Something went wrong');
      console.log(error);
    }
  };

  return (
    <>
      <Header />
      <div className="sign-div">
        <h1 className="title"> Sign in to your UBEPSA account </h1>

        {/* SIGN UP FORM */}
        <form action="#" className="sign-form" onSubmit={handleSubmit}>
          {/* FORM ITEM */}
          <span className="form-item">
            <label htmlFor="email"> Your Email: </label>

            <input
              type="email"
              placeholder="Email"
              name="email"
              id="email"
              value={form.email}
              onChange={handleChange}
              autoComplete="true"
            />
          </span>

          {/* FORM ITEM */}
          <span className="form-item password">
            <img src={showPassword} alt="" onClick={handlePwState} />
            <label htmlFor="password"> Password: </label>

            <input
              type={pwState}
              name="password"
              id="password"
              value={form.password}
              onChange={handleChange}
            />
          </span>

          <input type="submit" value={'Sign in'} id="submit-button" />
          <p>
            Don't have an account ? <Link to={'/sign-up'}>Sign Up</Link>
          </p>
        </form>
      </div>
    </>
  );
}

import '../CSS/QuickAccess.css';
import SNQuick from './SNQuick.jsx';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { API } from '../api.js';
import { Link } from 'react-router-dom';

//MAIN COMPONENT BODY
export default function QuickAccess() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getPosts = async () => {
      try {
        setLoading(true);
        const res = await axios.get(`${API}/post/all`, {
          withCredentials: true,
        });
        const data = res.data;
        setPosts(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };
    getPosts();
  }, []);

  const topFour = [posts[1], posts[2], posts[3]];
  const scope = posts[0];
  const quickEl = topFour.map((top) => {
    return <SNQuick {...top} key={top?._id} />;
  });

  return (
    <section className="quickAccess division small-division">
      <article
        className="bigScoop"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-once="true"
      >
        <h1> Scoop of the week </h1>
        <h3> {scope?.title || 'Loading...'} </h3>
        <p
          dangerouslySetInnerHTML={
            scope?.content
              ? {
                  __html:
                    scope?.content.split(' ').slice(0, 25).join(' ') + '...',
                }
              : { __html: 'Loading...' }
          }
        />
        <Link className="scopeLink" to={`post/${scope?.slug}`}>
          {' '}
          Read more {'>'}{' '}
        </Link>
      </article>

      {/*  <!-- DIV FOR SECONDARY NEWS --> */}
      <div className="otherNews">
        {/*  <!-- INDIVIDUAL NEWS TABS --> */}
        {quickEl}
      </div>

      {/*  <!-- END OF OTHER NEWS TAB --> */}
    </section>
  );
}

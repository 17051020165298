import '../CSS/QuickAccess.css';
import { Link } from 'react-router-dom';
export default function SNQuick({ title, content, slug, _id }) {
  return (
    <article
      className="news"
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-once="true"
      data-aos-delay="800"
    >
      <h2>{title ? title : 'Loading...'}</h2>
      <p
        className="newsContent"
        dangerouslySetInnerHTML={
          content
            ? { __html: content?.split(' ').slice(0, 20).join(' ') + '...' }
            : { __html: 'Loading...' }
        }
      />

      <Link to={`/post/${slug}`} key={_id}>
        {' '}
        Read more
      </Link>
    </article>
  );
}
